import { api } from './api';

export async function ListFloors(idBusiness, idAndar, searchTxt, page, limit, token) {
    try {
        let baseURL = `/andar?limit=${limit || 10}`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (searchTxt) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`
        }
        if (idBusiness) {
            baseURL = `${baseURL}&idBusiness=${idBusiness}`
        }
        if (idAndar) {
            baseURL = `${baseURL}&idAndar=${idAndar}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CreateFloors(name, andarImage, periodStart, periodEnd, idBusiness, token) {
    try {
        const response = await api.post('/andar', { name, andarImage, periodStart, periodEnd, idBusiness }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EditFloors(points, name, andarImage, periodStart, periodEnd, idBusiness, idAndar, token) {
    try {
        const response = await api.put(`/andar?idAndar=${idAndar}`, { points, name, andarImage, periodStart, periodEnd, idBusiness }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function DeleteFloors(idAndar, token) {
    try {
        const response = await api.delete(`/andar?idAndar=${idAndar}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}