import React from "react";
// Chakra imports
import {
  Flex,
  Spinner,
  Text
} from "@chakra-ui/react";

function Loading() {
    return (
        <Flex
            w='100%'
            h='100%'
            alignItems='center'
            justifyContent='center'
            flexDirection='column'
            position='fixed'
            top='0'
            left='0'
        >
            <Spinner 
                thickness='10px'
                speed='0.65s'
                emptyColor='gray.200'
                color="main.700"
                style={{ width: 150, height: 150 }}
            />
            <Text color='secondaryGray.700' mt="20px" fontSize='xl' fontWeight='400'>Carregando dados...</Text>
        </Flex>
    );
}

export default Loading;
