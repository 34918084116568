/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import LoadingLayout from 'views/loading';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { AuthProvider } from 'contexts/SidebarContext';
import { useAuthContext } from 'contexts/SidebarContext';

const Routes = () => {
    const { user } = useAuthContext();

    const [openSession, setOpenSession] = useState(false);

    useEffect(() => {
        if (!openSession) {
            const dataURL = window.location.href.split('?id=')[1];
            const getID = dataURL ? dataURL.split('&qtd=')[0] : '';
            const getQTD = dataURL ? dataURL.split('&qtd=')[1] : '';
            if (getID && getQTD) {
                window.sessionStorage.setItem('@indoor_idToken', getID);
                window.sessionStorage.setItem('@indoor_qtdToken', getQTD);
            }
            verifyUser();
        }
    }, [user]);

    const verifyUser = () => {
        try {
            const dataUserSave = window.localStorage.getItem('@indoor_authenticated');
            const dataUserParse = JSON.parse(dataUserSave);
            if (dataUserParse.tokenRefresh && user) {
                setOpenSession(true);
            }
        } catch (error) {
            setOpenSession(true);
        }
    };

    if (!openSession) {
        return (
            <>
                <Route path={`/loading`} component={LoadingLayout} />
                <Redirect from='/' to='/loading' />
            </>
        );
    }
    if (user?.token) {
        return (
            <>
                <Route path={`/admin`} component={AdminLayout} />
                <Redirect from='/' to='/admin/default' />
            </>
        );
    }
    return (
        <>
            <Route path={`/auth`} component={AuthLayout} />
            <Redirect from='/' to='/auth/sign-in' />
        </>
    )
}

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
            <AuthProvider>
                <ThemeEditorProvider>
                    <HashRouter>
                        <Switch>
                            <Routes />
                        </Switch>
                    </HashRouter>
                </ThemeEditorProvider>
            </AuthProvider>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);
