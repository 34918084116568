import { api } from './api';

export async function ListInfo(idAndar, token) {
    try {
        let baseURL = `/ble/count?idAndar=${idAndar}`;
        const response = await api.get(baseURL, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function ListBLE(idBusiness, idAndar, idBle, stock, searchTxt, page, limit, token) {
    try {
        let baseURL = `/ble?limit=${limit || 10}`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (searchTxt) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`
        }
        if (idBusiness) {
            baseURL = `${baseURL}&idBusiness=${idBusiness}`
        }
        if (stock) {
            baseURL = `${baseURL}&stock=${stock}`
        }
        if (idAndar) {
            baseURL = `${baseURL}&idAndar=${idAndar}`
        }
        if (idBle) {
            baseURL = `${baseURL}&idBle=${idBle}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CreateBLE(simpleId, uuid, macAddress, token) {
    try {
        const response = await api.post('/ble', { simpleId, uuid, macAddress }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EditBLE(idAndar, simpleId, uuid, macAddress, positionX, positionY, enabled, idBle, token) {
    try {
        const response = await api.put(`/ble?idBle=${idBle}`, { idAndar, simpleId, uuid, macAddress, positionX, positionY, enabled }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EnabledBLE(idBle, token) {
    try {
        const response = await api.patch('/ble/enabled', { idBle }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function DeleteBLE(idBle, token) {
    try {
        const response = await api.delete(`/ble?idBle=${idBle}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}
