/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import React, { useState } from "react";

// Custom components
import Card from "components/card/Card";
import { Field, Form, Formik } from "formik";
// import Dropzone from "views/admin/profile/components/Dropzone";
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { DeleteBusiness } from "services/business";
import { CreateBusiness } from "services/business";
import { EditBusiness } from "services/business";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { addHours, lightFormat } from "date-fns";
import ConfirmModal from "components/confirmModal";

export default function CompanyTable(props) {
    const { list, getList, totalPages, currentPage, setCurrentPage, searchTxt, setSearchTxt, setFrame, setInfoCompany } = props;

    const { user } = useAuthContext();
  
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const [isOpen, setIsOpen] = useState(false);
    const [infoRow, setInfoRow] = useState(null);
    const [isOpenDelete, setIsOpenDelete] = useState(false);

    const onClose = () => {
        setIsOpen(false);
        setInfoRow(null);
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('O nome da empresa é obrigatório'),
    });

    const onClickEdit = (item) => {
        setInfoRow(item);
        setIsOpen(true);
    };

    const onClickView = async (item) => {
        setFrame(2);
        setInfoCompany(item);
    };

    const onClickDelete = async (item) => {
        setInfoRow(item);
        setIsOpenDelete(true);
    };

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}
        bg="transparent"  
    >
        <Flex bg="white" borderRadius="20px" justifyContent="space-between" padding="20px" mb="20px">
            <SearchBar
                placeholder="Buscar empresa"
                value={searchTxt}
                setValue={setSearchTxt}
            />
            <Button onClick={() => setIsOpen(true)} w='200px' h='44px' variant="solid" bg="main.700" color="white">
                Criar empresa
            </Button>
        </Flex>
        <Flex gap='16px' position="block" flexWrap="wrap">
            {list.map((v) => (
                <Card
                    direction='column'
                    bg="white"
                    align="center"
                    justify="center"
                    w={{ base: "100%", md: "49%", xl: "49%" }}
                    height="260px"
                    justifyContent="space-between"
                    boxShadow="0px 18px 40px rgba(112, 144, 176, 0.12)"
                    key={v.id}
                    p="0px"
                >
                    <Flex p="10px 20px 0px 20px" alignItems="center" justifyContent="space-between">
                        <Text color='main.700' fontSize='3xl' fontWeight='700'>{v.name}</Text>
                        <Flex gap="10px" bg="white">
                            <Icon onClick={() => onClickEdit(v)} cursor="pointer" as={FiEdit} width='20px' height='20px' color='secondaryGray.700' />
                            <Icon onClick={() => onClickDelete(v)} cursor="pointer" as={FiTrash2} width='20px' height='20px' color='secondaryGray.700' />
                        </Flex>
                    </Flex>
                    <Flex cursor="pointer" onClick={() => onClickView(v)} p="0px 20px 0px 20px" flex={1} justifyContent="space-evenly" alignItems="center" width="100%" alignSelf="center">
                        <Card
                            direction='column'
                            bg="main.600"
                            align="center"
                            justify="center"
                            width="116px"
                            height="97px"
                            paddingTop="5px"
                            borderRadius="5px"
                        >
                            <Text color='white' fontSize='3xl' fontWeight='700'>{v.countAndar || 0}</Text>
                            <Text color='white' fontSize='large' fontWeight='400'>Andares</Text>
                        </Card>
                        <Card
                            direction='column'
                            bg="main.600"
                            align="center"
                            justify="center"
                            width="116px"
                            height="97px"
                            paddingTop="5px"
                            borderRadius="5px"
                        >
                            <Text color='white' fontSize='3xl' fontWeight='700'>{v.countBle || 0}</Text>
                            <Text color='white' fontSize='large' fontWeight='400'>BLEs</Text>
                        </Card>
                    </Flex>
                    <Text cursor="pointer" onClick={() => onClickView(v)} p="0px 20px 10px 20px" color='main.700' fontSize='sm' fontWeight='500'>{`Última atualização da planta: ${v?.andar?.andar_image_updated_at ? lightFormat(addHours(new Date(v.andar.andar_image_updated_at), 3), 'dd/MM/yyyy HH:mm') : '-'}`}</Text>
                </Card>
            ))}
        </Flex>
        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
        />
        <ToastContainer />
        <ConfirmModal
            isOpen={isOpenDelete}
            onClose={() => {
                setIsOpenDelete(false);
                setInfoRow(null);
            }}
            description="Tem certeza que deseja excluir permanentemente essa empresa?"
            title="Confirmação de exclusão"
            onAction={async () => {
                setIsOpenDelete(false);
                await DeleteBusiness(infoRow.id, user?.token);
                setInfoRow(null);
                getList();
            }}
        />
        <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="lg">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {infoRow ? 'Editar empresa' : 'Criar empresa'}
                    <Text color='secondaryGray.700' fontSize='xs' fontWeight='400'>{`Preencha as informações abaixo para ${infoRow ? 'editar' : 'criar'} uma empresa`}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody display="flex" flexDirection="column" alignItems="center">
                    <Formik
                        initialValues={{
                            name: infoRow?.name ? infoRow.name : '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, helpers) => {
                            try {
                                const response = !infoRow?.id ? await CreateBusiness(
                                    values.name,
                                    user?.token,
                                ) : await EditBusiness(
                                    values.name,
                                    infoRow?.id,
                                    user?.token,
                                );
                                if (response?.status === 200 || response?.status === 201) {
                                    toast(response?.message || 'Empresa salva com sucesso!');
                                    helpers.setSubmitting(false);
                                    onClose();
                                    getList();
                                } else {
                                    helpers.setStatus({ success: false });
                                    helpers.setErrors({ submit: response?.message });
                                    helpers.setSubmitting(false);
                                }
                            } catch (err) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: err.message });
                                helpers.setSubmitting(false);
                            }
                        }}
                    >
                        {(props) => (
                            <Form style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <Field name='name'>
                                    {({ field, form }) => (
                                        <FormControl mb="20px" isInvalid={form.errors.name && form.touched.name}>
                                            <FormLabel
                                                display='flex'
                                                ms='4px'
                                                fontSize='sm'
                                                fontWeight='500'
                                                color="main.700"
                                                mb='8px'
                                            >
                                                Nome da empresa<Text color="main.700">*</Text>
                                            </FormLabel>
                                            <Input
                                                {...field}
                                                variant='auth'
                                                fontSize='sm'
                                                ms={{ base: "0px", md: "0px" }}
                                                placeholder='Digite o nome da empresa'
                                                fontWeight='500'
                                                size='lg'
                                            />
                                            <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                {props.errors.submit && (
                                    <Text
                                        color={props.status.success ? 'green.500' : 'red.500'}
                                        fontSize='small'
                                        fontWeight='400'
                                        marginTop="20px"
                                    >
                                        {props.errors.submit}
                                    </Text>
                                )}
                                <Button
                                    variant='solid'
                                    bg="main.700"
                                    color="white"
                                    isLoading={props.isSubmitting}
                                    type="submit"
                                    alignSelf="flex-end"
                                    w="150px"
                                    marginTop="24px"
                                >
                                    {infoRow ? 'Editar empresa' : 'Criar empresa'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>

                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </Card>
  );
}
