/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Switch,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import React, { useEffect, useMemo, useState } from "react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { Field, Form, Formik } from "formik";
// import Dropzone from "views/admin/profile/components/Dropzone";
import { lightFormat } from "date-fns";
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { DeleteBLE } from "services/ble";
import { EnabledBLE } from "services/ble";
import { CreateBLE } from "services/ble";
import { EditBLE } from "services/ble";
import { ListBusiness } from "services/business";
import { ListFloors } from "services/floors";

export default function BleTable(props) {
    const { columnsData, tableData, getList, totalPages, currentPage, setCurrentPage, searchTxt, setSearchTxt, setIdBusiness } = props;

    const { user } = useAuthContext();
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);
  
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const tableInstance = useTable(
        {
        columns,
        data,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        initialState,
    } = tableInstance;
    initialState.pageSize = 10;

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    const [isOpen, setIsOpen] = useState(false);
    const [infoRow, setInfoRow] = useState(null);
    const [idAndar, setIdAndar] = useState(null);
    const [searchAndar, setSearchAndar] = useState('');
    const [idCompany, setIdCompany] = useState(null);
    const [searchCompany, setSearchCompany] = useState('');
    const [listCompany, setListCompany] = useState([]);
    const [listAndar, setListAndar] = useState([]);

    const onClose = () => {
        setIsOpen(false);
        setInfoRow(null);
        setIdAndar(null);
        setIdCompany(null);
        setSearchAndar('');
        setSearchCompany('');
    };

    const validationSchema = Yup.object().shape({
        simpleId: Yup.string().required('O simpleID é obrigatório'),
        uuid: Yup.string().required('O UUID é obrigatório'),
        macAddress: Yup.string().required('O Mac Address é obrigatório'),
        positionX: Yup.string().notRequired(),
        positionY: Yup.string().notRequired(),
        enabled: Yup.boolean(),
    });

    const onClickEdit = (item) => {
        setIdAndar(item.andar_id || null);
        setIdCompany(item.business_id || null);
        setSearchAndar(item.andar_name || '');
        setSearchCompany(item.business_name || '');
        setInfoRow(item);
        setIsOpen(true);
    };

    const onClickEnable = async (item) => {
        await EnabledBLE(item.id, user?.token);
        getList();
    };

    const onClickDelete = async (item) => {
        await DeleteBLE(item.id, user?.token);
        getList();
    };

    const handleOnSearchAndar = (string) => {
        setSearchAndar(string);
    }

    const handleOnSelectAndar = (item) => {
        setIdAndar(item.id);
    }

    const handleOnSearchCompany = (string, results) => {
        setSearchCompany(string);
    }

    const handleOnSelectCompany = (item, type) => {
        if (type === 'search') {
            setIdBusiness(item.id);
        } else {
            setIdCompany(item.id);
        }
    }

    const handleOnClearCompany = (type) => {
        setIdCompany(null);
        setSearchCompany('');
        setListCompany([]);
        getListCompany();
        if (type === 'search') {
            setIdBusiness(null);
        }
    }

    const handleOnClearAndar = (item) => {
        setIdAndar(null);
        setSearchAndar('');
        setListAndar([]);
        getListFloors();
    }

    const formatResult = (item) => {
        return (
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
        )
    }

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getListCompany();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [searchCompany]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (idCompany) {
                getListFloors();
            }
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [searchAndar, idCompany]);

    const getListCompany = async () => {
        const response = await ListBusiness(null, searchCompany, 1, null, user?.token);
        setListCompany((response?.result?.list || []).map((v) => ({
            name: v.name,
            id: v.id,
        })));
    };

    const getListFloors = async () => {
        const response = await ListFloors(idCompany, null, searchAndar, 1, null, user?.token);
        setListAndar((response?.result?.list || []).map((v) => ({
            name: v.name,
            id: v.id,
        })));
    };

    const onClickDirection = (item) => {
        window.sessionStorage.setItem('@indoor_business_id', JSON.stringify(item.business_id));
        window.location.reload();
    };

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
        <Box style={{ maxHeight: 150, minHeight: 150, overflowY: 'auto', padding: '0px 20px 0px 20px', maxWidth: 300 }}>
            <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color="main.700"
                mb='8px'
            >
                Empresa<Text color="main.700">*</Text>
            </FormLabel>
            <ReactSearchAutocomplete
                items={listCompany}
                onSearch={handleOnSearchCompany}
                onSelect={(item) => handleOnSelectCompany(item, 'search')}
                onClear={() => handleOnClearCompany('search')}
                formatResult={formatResult}
                placeholder="Selec. uma empresa"
                styling={{ fontSize: "14px" }}
                showItemsOnFocus
                autoFocus={false}
                inputSearchString={searchCompany}
            />
        </Box>
        <Flex justifyContent="space-between" padding="0px 20px 0px 20px" mb="20px">
            <SearchBar
                placeholder="Buscar BLE"
                value={searchTxt}
                setValue={setSearchTxt}
            />
            <Button onClick={() => setIsOpen(true)} w='200px' h='44px' variant="solid" bg="main.700" color="white">
                Criar BLE
            </Button>
        </Flex>

        <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="lg">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {infoRow ? 'Editar BLE' : 'Criar BLE'}
                    <Text color='secondaryGray.700' fontSize='xs' fontWeight='400'>{`Preencha as informações abaixo para ${infoRow ? 'editar' : 'criar'} um BLE`}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody display="flex" flexDirection="column" alignItems="center">
                    <Formik
                        initialValues={{
                            simpleId: infoRow?.simple_id ? infoRow.simple_id : '',
                            uuid: infoRow?.uuid ? infoRow.uuid : '',
                            macAddress: infoRow?.mac_address ? infoRow.mac_address : '',
                            positionX: infoRow?.position_x ? infoRow.position_x : '',
                            positionY: infoRow?.position_y ? infoRow.position_y : '',
                            enabled: infoRow?.enabled ? infoRow.enabled : false,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, helpers) => {
                            if (!idAndar && infoRow?.id) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: 'Selecione um andar' });
                                helpers.setSubmitting(false);
                                return null;
                            }
                            try {
                                const response = !infoRow?.id ? await CreateBLE(
                                    values.simpleId,
                                    values.uuid,
                                    values.macAddress,
                                    user?.token,
                                ) : await EditBLE(
                                    idAndar,
                                    values.simpleId,
                                    values.uuid,
                                    values.macAddress,
                                    values.positionX,
                                    values.positionY,
                                    values.enabled,
                                    infoRow?.id,
                                    user?.token,
                                );
                                if (response?.status === 200 || response?.status === 201) {
                                    toast(response?.message || 'BLE salvo com sucesso!');
                                    helpers.setSubmitting(false);
                                    onClose();
                                    getList();
                                } else {
                                    helpers.setStatus({ success: false });
                                    helpers.setErrors({ submit: response?.message });
                                    helpers.setSubmitting(false);
                                }
                            } catch (err) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: err.message });
                                helpers.setSubmitting(false);
                            }
                        }}
                    >
                        {(props) => (
                            <Form style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <Grid templateColumns='repeat(2, 1fr)' gap='16px' position="block">
                                    {!infoRow?.id ? null : (
                                        <>
                                            <Box style={{ maxHeight: 100, minHeight: 100, overflowY: 'auto', padding: '10px' }}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color="main.700"
                                                    mb='8px'
                                                >
                                                    Empresa<Text color="main.700">*</Text>
                                                </FormLabel>
                                                <ReactSearchAutocomplete
                                                    items={listCompany}
                                                    onSearch={handleOnSearchCompany}
                                                    onSelect={(item) => handleOnSelectCompany(item, '')}
                                                    onClear={() => handleOnClearCompany('')}
                                                    formatResult={formatResult}
                                                    placeholder="Selec. uma empresa"
                                                    styling={{ fontSize: "14px" }}
                                                    showItemsOnFocus
                                                    autoFocus={false}
                                                    inputSearchString={searchCompany}
                                                />
                                            </Box>
                                            <Box style={{ maxHeight: 100, minHeight: 100, overflowY: 'auto', padding: '10px' }}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color="main.700"
                                                    mb='8px'
                                                >
                                                    Andar<Text color="main.700">*</Text>
                                                </FormLabel>
                                                <ReactSearchAutocomplete
                                                    items={listAndar}
                                                    onSearch={handleOnSearchAndar}
                                                    onSelect={handleOnSelectAndar}
                                                    onClear={handleOnClearAndar}
                                                    formatResult={formatResult}
                                                    placeholder="Selec. um andar"
                                                    styling={{ fontSize: "14px" }}
                                                    showItemsOnFocus
                                                    autoFocus={false}
                                                    inputSearchString={searchAndar}
                                                />
                                            </Box>
                                        </>
                                    )}
                                    <Field name='simpleId'>
                                        {({ field, form }) => (
                                            <FormControl mb="20px" isInvalid={form.errors.simpleId && form.touched.simpleId}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color="main.700"
                                                    mb='8px'
                                                >
                                                    SimpleID BLE<Text color="main.700">*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Digitar ID exemplo: 0000f0'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.simpleId}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='uuid'>
                                        {({ field, form }) => (
                                            <FormControl mb="20px" isInvalid={form.errors.uuid && form.touched.uuid}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color="main.700"
                                                    mb='8px'
                                                >
                                                    UUID<Text color="main.700">*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Digitar UUID'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.uuid}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Grid>
                                <Field name='macAddress'>
                                    {({ field, form }) => (
                                        <FormControl mb="20px" isInvalid={form.errors.macAddress && form.touched.macAddress}>
                                            <FormLabel
                                                display='flex'
                                                ms='4px'
                                                fontSize='sm'
                                                fontWeight='500'
                                                color="main.700"
                                                mb='8px'
                                            >
                                                Mac address<Text color="main.700">*</Text>
                                            </FormLabel>
                                            <Input
                                                {...field}
                                                variant='auth'
                                                fontSize='sm'
                                                ms={{ base: "0px", md: "0px" }}
                                                placeholder='Digitar Mac Address'
                                                fontWeight='500'
                                                size='lg'
                                            />
                                            <FormErrorMessage>{form.errors.macAddress}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                {!infoRow?.id ? null : (
                                    <Grid templateColumns='repeat(2, 1fr)' gap='16px'>
                                        <Field name='positionX'>
                                            {({ field, form }) => (
                                                <FormControl mb="20px" isInvalid={form.errors.positionX && form.touched.positionX}>
                                                    <FormLabel
                                                        display='flex'
                                                        ms='4px'
                                                        fontSize='sm'
                                                        fontWeight='500'
                                                        color="main.700"
                                                        mb='8px'
                                                    >
                                                    Posição X<Text color="main.700">*</Text>
                                                    </FormLabel>
                                                    <Input
                                                        {...field}
                                                        variant='auth'
                                                        fontSize='sm'
                                                        ms={{ base: "0px", md: "0px" }}
                                                        placeholder='Digitar posição X'
                                                        fontWeight='500'
                                                        type="number"
                                                        size='lg'
                                                    />
                                                    <FormErrorMessage>{form.errors.positionX}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='positionY'>
                                            {({ field, form }) => (
                                                <FormControl mb="20px" isInvalid={form.errors.positionY && form.touched.positionY}>
                                                    <FormLabel
                                                        display='flex'
                                                        ms='4px'
                                                        fontSize='sm'
                                                        fontWeight='500'
                                                        color="main.700"
                                                        mb='8px'
                                                    >
                                                        Posição Y<Text color="main.700">*</Text>
                                                    </FormLabel>
                                                    <Input
                                                        {...field}
                                                        variant='auth'
                                                        fontSize='sm'
                                                        ms={{ base: "0px", md: "0px" }}
                                                        placeholder='Digitar posição Y'
                                                        fontWeight='500'
                                                        type="number"
                                                        size='lg'
                                                    />
                                                    <FormErrorMessage>{form.errors.positionY}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Grid>
                                )}
                                {!infoRow?.id ? null : (
                                    <Field name='enabled'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.enabled && form.touched.enabled}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Ativar BLE<Text color="main.700">*</Text>
                                                </FormLabel>
                                                <Switch
                                                    {...field}
                                                    size='lg'
                                                    colorScheme='brand'
                                                    isChecked={props.values.enabled}
                                                />
                                                <FormErrorMessage>{form.errors.enabled}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                )}
                                {props.errors.submit && (
                                    <Text
                                        color={props.status.success ? 'green.500' : 'red.500'}
                                        fontSize='small'
                                        fontWeight='400'
                                        marginTop="20px"
                                    >
                                        {props.errors.submit}
                                    </Text>
                                )}
                                <Button
                                    variant='solid'
                                    bg="main.700"
                                    color="white"
                                    isLoading={props.isSubmitting}
                                    type="submit"
                                    alignSelf="flex-end"
                                    w="150px"
                                    marginTop="24px"
                                >
                                    {infoRow ? 'Editar BLE' : 'Criar BLE'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>

                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='100px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "AÇÕES") {
                    return (
                        <Flex align='center' pl="30px" pt="5px">
                            <Menu row={cell.row.original} onClickEnable={onClickEnable} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
                        </Flex>
                    )
                  } else if (cell.column.Header === "PROJETO") {
                    data = (
                        <Text onClick={() => onClickDirection(cell.row.original)} color={textColor} fontSize='sm' fontWeight='700' textDecoration="underline" cursor="pointer">
                          {cell.value}
                        </Text>
                      );
                  } else if (cell.column.Header === "SITUAÇÃO") {
                    data = (
                      <Text color={cell.value ? 'green.500' : 'red.500'} fontSize='sm' fontWeight='700'>
                        {cell.value ? 'Ativo' : 'Inativo'}
                      </Text>
                    );
                  } else if (cell.column.Header === "DATA") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {lightFormat(new Date(cell.value), 'dd/MM/yyyy HH:mm')}
                      </Text>
                    );
                  } else {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
        />
        <ToastContainer />
    </Card>
  );
}
