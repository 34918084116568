export const columnsDataColumnsCompany = [
    {
        Header: "ID",
        accessor: "id",
    },
    {
        Header: "PROJETO",
        accessor: "business_name",
    },
    {
        Header: "DATA",
        accessor: "created_at",
    },
    {
        Header: "VIGÊNCIA",
        accessor: "vigency",
    },
    {
        Header: "BATERIA",
        accessor: "energy",
    },
    {
        Header: "POSIÇÃO X",
        accessor: "position_x",
    },
    {
        Header: "POSIÇÃO Y",
        accessor: "position_y",
    },
    {
        Header: "SITUAÇÃO",
        accessor: "enabled",
    },
    {
        Header: "AÇÕES",
        accessor: "actions",
    },
];
