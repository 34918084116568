/* eslint-disable react-hooks/exhaustive-deps */
// Chakra imports
import {
    Box
    // SimpleGrid,
} from "@chakra-ui/react";
import BleTable from "views/admin/bluetooth/components/BleTable";
import {
    columnsDataColumnsCompany,
} from "views/admin/bluetooth/variables/columnsData";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "contexts/SidebarContext";
import { ListBLE } from "services/ble";

export default function Bluetooth() {
    const { user } = useAuthContext();

    const [list, setList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTxt, setSearchTxt] = useState('');
    const [idBusiness, setIdBusiness] = useState(null);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getList();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [currentPage, searchTxt, idBusiness]);

    const getList = async () => {
        const response = await ListBLE(idBusiness, null, null, null, searchTxt, currentPage, null, user?.token);
        setList(response?.result?.list || []);
        setTotalPages(response?.result?.qtdPages || 1);
    };

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <BleTable
            columnsData={columnsDataColumnsCompany}
            tableData={list}
            getList={getList}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            searchTxt={searchTxt}
            setSearchTxt={setSearchTxt}
            setIdBusiness={setIdBusiness}
        />
    </Box>
  );
}
