export const maskCPF = (v) => {
    const cpfFormatted = v
        ?.replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1-$2');
    return cpfFormatted;
  };
  
export const MaskCNPJ = (v) => {
    const cnpjFormatted = v
        ?.replace(/\D+/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');

    return cnpjFormatted;
};

export const toPrice = (price, scale) =>
    String(((price * 100) / 100).toFixed(scale || 2))
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  
export const maskPhone = (v) => {
    let valueInitial = v?.replace(/\D/g, '').replace(/^0/, '') || '';
    if (valueInitial.length > 11) {
        valueInitial = valueInitial.replace(
            /^(\d\d)(\d{5})(\d{4}).*/,
            '($1) $2-$3'
        );
    } else if (valueInitial.length > 7) {
        valueInitial = valueInitial.replace(
            /^(\d\d)(\d{5})(\d{0,4}).*/,
            '($1) $2-$3'
        );
    } else if (valueInitial.length > 2) {
        valueInitial = valueInitial.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else if (v?.trim() !== '') {
        valueInitial = valueInitial.replace(/^(\d*)/, '($1');
    }
    return valueInitial;
};

export const MaskCep = (v) =>
    v
        .replace(/\D/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{3})\d+?$/, '$1');

export const moneyMask = (value) => {
    const valueFormat = value
        .replace('.', '')
        .replace(',', '')
        .replace(/\D/g, '');

    const options = { minimumFractionDigits: 2 };
    const result = new Intl.NumberFormat('pt-BR', options).format(
        parseFloat(valueFormat) / 100
    );

    return `R$ ${result === 'NaN' ? '0,00' : result}`;
};

export const priceUnmask = (value) => {
    const valueFormat = Number(
        value.replace('R$', '').replace(/[.]/g, '').replace(',', '.')
    );
    return valueFormat;
};

export const getNameWithExtensionFromUrl = (url, uuid) => {
    const extension = url.slice(-4);
    // const name = url.substring(0, 60).split(extension)[0];
    return `imagem_indor_${uuid.substring(0, 10)}${extension}`;
};
